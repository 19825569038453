<template>
    <div>
        <CCard>
            <CCardBody>
                <b-row>
                    <b-col class="text-right">
                        <b-button @click.prevent="$router.go(-1)">{{ $t('buttons.prev') }}</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" md="6" lg="4" xl="3">
                        <h4>{{ $t('pages.content.faqManagement.headers.detail') }}</h4>
                        <b-table-simple responsive striped small>
                            <b-tbody>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.name', {language: $t('generally.languages.' + locale)}) }}:</b-td>
                                    <b-td>
                                        {{ data.name }}
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.tags.label') }}:</b-td>
                                    <b-td><span v-if="data.tags && Array.isArray(data.tags)">{{ data.tags.join(', ') }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.public') }}:</b-td>
                                    <b-td>
                                        <font-awesome-icon :class="data.public ? 'text-success' : 'text-danger'" :icon="data.public ? 'check' : 'times'"/>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.owner') }}:</b-td>
                                    <b-td><span v-if="data.owner">{{ data.owner.firstName }} {{ data.owner.lastName }}</span></b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.created') }}:</b-td>
                                    <b-td>{{ $moment(data.createdAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ $t('pages.content.faqManagement.form.updated') }}:</b-td>
                                    <b-td>{{ $moment(data.updatedAt).format('DD.MM.YYYY HH:mm') }}</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                </b-row>
            </CCardBody>
        </CCard>
        <b-row v-if="data.question">
            <b-col md="6">
                <CCard>
                    <CCardHeader v-b-toggle="'faq-de-' + data.id">
                        <img :src="data.image" alt="Logo" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                        <b-icon-question-circle variant="orange" v-show="!picture"></b-icon-question-circle>&nbsp;
                        <strong>{{ data.question.de }}</strong>
                    </CCardHeader>
                    <b-collapse :id="'faq-de-' + data.id">
                        <CCardBody v-html="data.answer.de"></CCardBody>
                    </b-collapse>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader v-b-toggle="'faq-en-' + data.id">
                        <img :src="data.image" alt="Logo" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                        <b-icon-question-circle variant="orange" v-show="!picture"></b-icon-question-circle>&nbsp;
                        <strong>{{ data.question.en }}</strong>
                    </CCardHeader>
                    <b-collapse :id="'faq-en-' + data.id">
                        <CCardBody v-html="data.answer.en"></CCardBody>
                    </b-collapse>
                </CCard>
            </b-col>
        </b-row>
        <CCard>
            <CCardBody>
                <user-to-resource-list :data="data.users" attr="users_downloads"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {EventBus} from "../../../services/EventBus";
import {getClassNameForFilename} from "../../../services/font-awesome-filetypes";
import fileService from "../../../services/fileService";
import mainServices from "../../../services/mainServices";
import UserToResourceList from "../../../components/UserToResourceList";
import faqService from "../../../services/faqService";

export default {
    name: "Detail",
    components: {UserToResourceList},
    props: ["id"],
    data: () => ({
        data: {},
        fields: [],
        image: '',
        picture: '',
        locale: 'de'
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.loadData();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.$toastr.i(this.$t('generally.syncMessage'));
            this.loadData();
        });
    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        loadData() {
            faqService.get(this.id).then(response => {
                this.data = response.data;
            }).catch();
        },
        getIcon(filename) {
            return getClassNameForFilename(filename);
        },
        bytesToSize(bytes) {
            return fileService.bytesToSize(bytes);
        }
    }
};
</script>

<style scoped>

</style>
